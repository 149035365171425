<template>
<div>
    <n-data-table
        ref="dataTableInst"
        row-class-name="p-0"
        :data="info"
        :loading="loading"
        :scroll-x="scrollX"
        :single-line="false"
        :columns="tableColumns"
        @update:sorter="sortColumn" />
    
    <div class="flex flex-row justify-center lg:justify-end mt-6">
        <n-pagination
            :size="gl.screenWidth < 1024 ? 'small' : 'large'"
            v-model:page="page"
            :disabled="loading"
            :page-count="pager.total" />
    </div>
</div>
</template>

<script>
// vue
import { h, ref, computed, watch, onMounted } from 'vue';

// router
import { useRoute, useRouter } from 'vue-router';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useEnv } from '@store/ts/env';
import { useMarketplace } from '@store/ts/marketplace';

// naive-ui
import {
    NIcon,
    NSwitch,
    NButton,
    NPopover,
    NSkeleton,
    NDataTable,
    NPagination } from 'naive-ui';

// components
import RbCoin from '@/components/rb-coin';

// icons
import {
    Info24Filled,
    Edit20Regular,
    Cart20Regular,
    CommentNote20Filled,
    ChartMultiple20Regular,
    DeleteDismiss24Regular } from '@vicons/fluent';

export default {
    name: 'GridTable',
    props: {
        data: {
            type: Array,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        pager: {
            type: Object,
            required: true,
        },
        role: {
            type: String,
            default: () => 'buy',
        },
        scrollX: {
            type: [Number, String],
            default: () => 3500,
        },
    },
    components: {
        NIcon,
        RbCoin,
        NSwitch,
        NPopover,
        NSkeleton,
        NDataTable,
        NPagination,
        Info24Filled,
        CommentNote20Filled,
    },
    setup(props, { emit, slots }) {
        const gl = useGl();
        const env = useEnv();
        const refs = useRefs();
        const marketplace = useMarketplace();

        const route = useRoute();
        const router = useRouter();

        const page = ref(props.currentPage);
        watch(page, (v) => {
            if (v)
                emit('setPage', v);
        });
        watch(() => props.currentPage, (v) => {
            page.value = v;
        });

        const dataTableInst = ref(null);

        const paintCell = (row) => {
            if (props.role === 'myMentors') {
                switch (row.state) {
                    case 'finished':
                        return 'flex flex-row justify-center text-gray-800 items-center h-full bg-green-300';
                    case 'await_confirmation':
                        return 'flex flex-row justify-center text-gray-800 items-center h-full bg-yellow-200';
                    case 'expired':
                        return 'flex flex-row justify-center text-gray-800 items-center h-full bg-red-200';
                }
            } else if (props.role === 'iMentor') {
                switch (row.state) {
                    case 'confirmed':
                        return 'flex flex-row justify-center text-gray-800 items-center h-full bg-green-300';
                    case 'await_confirmation':
                        return 'flex flex-row justify-center text-gray-800 items-center h-full bg-yellow-200';
                    case 'canceled':
                        return 'flex flex-row justify-center text-gray-800 items-center h-full bg-red-200';
                }
            }
        };

        const tableColumns = computed(() => props.columns
            .filter(el => !el.isHidden)
            .map(el => (
                {
                    key: el.name,
                    sorter: el.isSortable,
                    order: false,
                    render: (row, i) => {
                        if (el.name === 'config_price' || el.name === 'mentorship_price') {
                            return h('div', {
                                class: paintCell(row),
                            }, [
                                h('div', {
                                    class: 'text-center',
                                }, [
                                    h(RbCoin, {
                                        coin: currency(env.profitSymbol),
                                    }),
                                    h('div', {
                                        class: 'text-main font-semibold',
                                    }, row[el.name]),
                                    h('div', env.profitSymbol),
                                ]),
                            ]);
                        } else if (el.name === 'name') {
                            return h('div', {
                                class: `text-center underline cursor-pointer hover:no-underline ${paintCell(row)}`,
                                onClick() {
                                    switch(props.role) {
                                        case 'buy':
                                            return void router.push({ name: 'marketplace.buy.review', params: { id: row.id } });
                                        case 'sell':
                                            return void router.push({ name: 'marketplace.sell.review', params: { id: row.id } });
                                        case 'mentorship':
                                            return void emit('needHelp', row.name);
                                    }
                                },
                            }, row[el.name]);
                        } else if (el.name === 'exchange') {
                            return h('div', {
                                class: `flex justify-center ${paintCell(row)}`,
                            }, [
                                h('span', {
                                    class: `inline-block rb-td-stock-icon rb-td-stock-icon-${exchange(row[el.name]).code_name}`,
                                }),
                            ]);
                        } else if (el.name === 'pair') {
                            return h('div', {
                                class: `text-center ${paintCell(row)}`,
                            }, [
                                h(RbCoin, {
                                    coin: currency(row[el.name].split('/').shift().trim()),
                                }),
                                h('div', {
                                    class: 'text-base',
                                }, row[el.name]),
                            ]);
                        } else if (el.name === 'algo') {
                            return h('div', {
                                class: paintCell(row),
                            }, algos(row[el.name]).title);
                        } else if (el.name === 'depo') {
                            return h('div', {
                                class: `text-center ${paintCell(row)}`,
                            }, [
                                h(RbCoin, {
                                    coin: currency(row.depo_coin),
                                }),
                                h('div', {
                                    class: 'text-main font-semibold',
                                }, row[el.name]),
                                h('div', row.depo_coin),
                            ]);
                        } else if (el.name === 'depo_usdt') {
                            return h('div', {
                                class: `text-center ${paintCell(row)}`,
                            }, [
                                h(RbCoin, {
                                    coin: currency(row.depo_coin),
                                }),
                                h('div', {
                                    class: 'text-main font-semibold',
                                }, row[el.name]),
                                h('div', row.depo_coin),
                            ]);
                        } else if (el.name === 'profit_coin') {
                            return h('div', {
                                class: `text-center ${paintCell(row)}`,
                            }, [
                                h(RbCoin, {
                                    coin: currency(row[el.name]),
                                }),
                                h('div', {
                                    class: 'text-main font-semibold uppercase',
                                }, row[el.name]),
                            ]);
                        } else if (el.name === 'total_profit') {
                            return h('div', {
                                class: `text-center ${paintCell(row)}`,
                            }, [
                                h(RbCoin, {
                                    coin: currency(row.total_profit_coin),
                                }),
                                h('div', {
                                    class: 'text-main font-semibold uppercase',
                                }, row[el.name]),
                            ]);
                        } else if (el.name === 'date_updated' || el.name === 'date_published') {
                            return h('div', {
                                class: `text-center ${paintCell(row)}`,
                            }, [
                                h('div', {
                                    class: 'font-semibold',
                                }, row[el.name].split(' ')[0]),
                                h('div', row[el.name].split(' ')[1]),
                            ]);
                        } else if (el.name === 'notes' || el.name === 'question') {
                            return h('div', {
                                class: (row[el.name] ? 'cursor-pointer' : '') + ` flex flex-row items-center justify-center ${paintCell(row)}`,
                            }, [
                                h(NPopover, {
                                    trigger: 'hover',
                                    placement: 'left',
                                    disabled: !row[el.name],
                                }, {
                                    trigger: () => h(NIcon, [
                                        h(CommentNote20Filled, {
                                            class: (row[el.name] ? 'text-main' : 'text-gray-500') + ' text-2xl',
                                        }),
                                    ]),
                                    default: () => h('div', {
                                        innerHTML: row[el.name],
                                        class: 'max-w-screen-sm text-gray-700 dark:text-white back_note',
                                    }),
                                }),
                            ]);
                        } else if (el.name === 'status') {
                            return h('div', {
                                class: `text-green-500 font-semibold text-center text-base ${paintCell(row)}`,
                            }, refs.botStatuses.find(cur => cur.id === row[el.name]).title);
                        } else if (el.name === 'actions') {
                            if (props.role === 'buy') {
                                return h('div', {
                                    class: `flex flex-row items-center justify-center cursor-pointer ${paintCell(row)}`,
                                }, [
                                    h(NPopover, {
                                        'trigger': 'hover',
                                        'placement': 'left',
                                        'class': 'bg-main',
                                        'arrow-style': {
                                            backgroundColor: gl.mainColor,
                                        },
                                    }, {
                                        trigger: () => h('span', {
                                            onClick() {
                                                router.push({ name: 'marketplace.buy.review', params: { id: row.id } });
                                            },
                                        }, [
                                            h(NIcon, [
                                                h(Info24Filled, {
                                                    class: 'text-main text-2xl',
                                                }),
                                            ]),
                                        ]),
                                        default: () => h('div', {
                                            innerHTML: marketplace.localization['marketplace/buy/grid/actions/review'],
                                            class: 'max-w-screen-sm text-gray-700 dark:text-white',
                                        }),
                                    }),
                                ]);
                            } else if (props.role === 'mentorship') {
                                return h('div', {
                                    class: `flex flex-row items-center justify-center cursor-pointer ${paintCell(row)}`,
                                }, [
                                    h(NPopover, {
                                        'trigger': 'hover',
                                        'placement': 'left',
                                        'class': 'bg-main',
                                        'arrow-style': {
                                            backgroundColor: gl.mainColor,
                                        },
                                    }, {
                                        trigger: () => h('span', {
                                            onClick() {
                                                emit('needHelp', row.name);
                                            },
                                        }, [
                                            h(NIcon, [
                                                h(Cart20Regular, {
                                                    class: 'text-main text-2xl',
                                                }),
                                            ]),
                                        ]),
                                        default: () => h('div', {
                                            innerHTML: marketplace.localization['marketplace/mentors/grid/actions/inquire_mentorship'],
                                            class: 'max-w-screen-sm text-gray-700 dark:text-white',
                                        }),
                                    }),
                                ]);
                            }  else if (props.role === 'myMentors' || props.role === 'iMentor') {
                                return h('div', {
                                    class: `flex flex-row items-center justify-center cursor-pointer ${paintCell(row)}`,
                                }, [
                                    h(NPopover, {
                                        'trigger': 'hover',
                                        'placement': 'left',
                                        'class': 'bg-main',
                                        'arrow-style': {
                                            backgroundColor: gl.mainColor,
                                        },
                                    }, {
                                        trigger: () => h('span', {
                                            onClick() {
                                                emit('onQuestionInfo', row.id);
                                            },
                                        }, [
                                            h(NIcon, [
                                                h(Info24Filled, {
                                                    class: 'text-main text-2xl',
                                                }),
                                            ]),
                                        ]),
                                        default: () => h('div', {
                                            innerHTML: marketplace.localization['marketplace/mentors/grid/actions/question_info'],
                                            class: 'max-w-screen-sm text-gray-700 dark:text-white',
                                        }),
                                    }),
                                ]);
                            } else if (props.role === 'sell') {
                                const dataAction = [
                                    {
                                        title:
                                            row.in_market
                                                ? marketplace.localization['marketplace/sell/grid/actions/removeFromMarket']
                                                : marketplace.localization['marketplace/sell/grid/actions/addToMarket'],
                                        method: () => {
                                            if (row.in_market) {
                                                emit('tryDeleteBot', row.id);
                                            } else {
                                                router.push({ name: 'marketplace.sell.review', params: { id: row.id } });
                                            }
                                        },
                                        icon: row.in_market ? DeleteDismiss24Regular : Cart20Regular,
                                        iconClass: row.in_market ? 'text-red-500' : 'text-main',
                                    }, {
                                        title: marketplace.localization['marketplace/sell/grid/actions/statistics'],
                                        method: () => {
                                            // route to trade-statistic/management/:id
                                        },
                                        icon: ChartMultiple20Regular,
                                        iconClass: 'text-main',
                                    }, {
                                        title: marketplace.localization['marketplace/sell/grid/actions/edit'],
                                        method: () => {
                                            emit('tryEditBot', row.id);
                                        },
                                        icon: Edit20Regular,
                                        iconClass: 'text-main',
                                    },
                                ];

                                return h('div', {
                                    class: `flex flex-row items-center justify-between pr-2 cursor-pointer ${paintCell(row)}`,
                                }, [
                                    ...dataAction.map(el => h(NPopover,  {
                                        'trigger': 'hover',
                                        'placement': 'top',
                                        'class': 'bg-main',
                                        'arrow-style': {
                                            backgroundColor: gl.mainColor,
                                        },
                                    }, {
                                        trigger: () => h('span', {
                                            onClick() {
                                                el.method();
                                            },
                                        }, [
                                            h(NIcon, {
                                                class: el.iconClass,
                                            }, [
                                                h(el.icon, {
                                                    class: 'text-2xl',
                                                }),
                                            ]),
                                        ]),
                                        default: () => h('div', {
                                            innerHTML: el.title,
                                            class: 'max-w-screen-sm text-gray-700 dark:text-white',
                                        }),
                                    })),
                                ]);
                            }
                            
                        } else if (el.name === 'in_market') {
                            return h('div', {
                                class: `flex flex-row items-center justify-center cursor-pointer ${paintCell(row)}`,
                            }, [
                                h(NSwitch, {
                                    size: 'large',
                                    value: row[el.name],
                                    onClick(e) {
                                        e.preventDefault();

                                        row[el.name]
                                            ? emit('tryDeleteBot', row.id)
                                            : router.push({ name: 'marketplace.sell.review', params: { id: row.id } });
                                    },
                                }, {
                                    checked: () => marketplace.localization['marketplace/sell/grid/actions/in_market__yes'],
                                    unchecked: () => marketplace.localization['marketplace/sell/grid/actions/in_market__no'],
                                }),
                            ]);
                        } else if (el.name === 'exchanges') {
                            return h('div', {
                                class: `flex flex-row flex-wrap max-w-[570px] ${paintCell(row)}`,
                            }, [...row[el.name].map(item => h('div', {
                                class: `p-2 border m-1 ${gl.darkTheme ? 'border-gray-800' : 'border-gray-200'}`,
                            }, [
                                h('span', {
                                    class: `inline-block rb-td-stock-icon rb-td-stock-icon-${exchange(item).code_name}`,
                                }),
                            ]))]);
                        } else if (el.name === 'state') {
                            if (row[el.name] === 'confirmed') {
                                return h('div', {
                                    class: `flex justify-center ${paintCell(row)}`,
                                }, [
                                    h(NButton, {
                                        strong: true,
                                        type: 'primary',
                                        onClick() {
                                            emit('onQuestionInfo', row.id);
                                        },
                                    }, marketplace.localization['marketplace/my_mentors/grid/mentorship_done']),
                                ]);
                            } else if (row[el.name] === 'expired') {
                                return h('div', {
                                    class: `flex justify-center ${paintCell(row)}`,
                                }, [
                                    h(NButton, {
                                        strong: true,
                                        type: 'warning',
                                        onClick() {
                                            emit('onQuestionInfo', row.id);
                                        },
                                    }, marketplace.localization['marketplace/my_mentors/grid/mentorship_done']),
                                ]);
                            } else return h('div', {
                                class: `text-center ${paintCell(row)}`,
                            }, marketplace.mentorshipRequestStates.find(({ id }) => id === row[el.name]).title );
                        } else if (el.name === 'subject' || el.name === 'mentor' || el.name === 'contact') {
                            return h('div', 
                                {
                                    innerHTML: row[el.name],
                                    class: `font-semibold text-center ${paintCell(row)}`,
                                });
                        } else if (el.name === 'id') {
                            return h('div', 
                                {
                                    innerHTML: '#' + row[el.name],
                                    class: `font-semibold text-center ${paintCell(row)}`,
                                });
                        } else {
                            return h('div',
                                {
                                    innerHTML: row[el.name],
                                    class: `text-center ${paintCell(row)}`,
                                });
                        }
                    },
                    ...el,
                }
            )));

        const sortColumn = ($event) => {
            if (!$event) return;

            if ($event.order === 'descend') {
                emit('sortColumn', $event.columnKey);
            } else {
                setTimeout(() => {
                    dataTableInst.value.sort(null);
                }, 0);
            }
        };

        const info = computed(() => props.data.map((el, i) => (
            {
                key: i,
                ...el,
            }
        )));

        const currency = (icon) => {
            return refs.currencies.find(el => el.id === icon);
        };

        const exchange = (exchange) => {
            return refs.exchanges.find(el => el.id === exchange);
        };

        const algos = (algo) => {
            return marketplace.algos.find(el => el.id === algo);
        };

        // onMounted(() => {
            
        // });

        return {
            gl,
            info,
            page,
            marketplace,
            tableColumns,
            dataTableInst,
            sortColumn,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '@/css/stock-icon.scss';

:deep(td) {
    height: 100px;
    padding: 0 !important;

    & > * {
        padding: 4px;
    }
}
</style>